@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* background-image: url('./Images/bg-img.png'); */
  /* background-size: cover; */
  background-color: rgb(248 250 252);
}

.side-menu-mobile {
  background-color: rgb(249 250 251 / 1);
  margin-top: 10px;
}

.mobile-modal {
  overflow-y: auto;
  max-height: 530px;
  width: 90vw;
}

.navbar-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

#sidebar-multi-level-sidebar {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

@media (max-width: 768px) {
  .h-91vh {
    height: 88vh;
    overflow: auto;
  }
}

@media (min-width: 769px) {
  .h-91vh {
    height: 93vh;
    overflow: auto;
  }
}

.custom-txt-color {
  color: #d9f2ff;
}
.custom-bg-color {
  background-color: #3b4a6b;
}

:root {
  --borderWidth: 7px;
  --height: 24px;
  --width: 12px;
  --borderColor: #78b13f;
}

.check {
  display: inline-block;
  transform: rotate(45deg);
  height: 40px;
  width: 22px;
  border-bottom: 7px solid #78b13f;
  border-right: 7px solid #78b13f;
}

.disableBtn {
  pointer-events: none;
  opacity: 0.6;
}

.invoice-input {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.notification:hover p {
  display: block;
}
.notification p {
  display: none;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeInDown {
  animation: fadeInDown 1s ease-in-out;
}

.animate-fadeInUp {
  animation: fadeInUp 1s ease-in-out;
}

.animate-bounce {
  animation: bounce 2s infinite;
}

.task-success {
  background-color: green;
}
.task-failure {
  background-color: red;
}
